import axios from 'axios';

const http = axios.create({
    baseURL: '',
    timeout: 180 * 1000,
});

http.interceptors.request.use(
    (config) => {
        // 在发送请求前做些什么
        return config;
    },
    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error);
    },
);

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
    return response
}, error => {
    console.error(error)
    return Promise.reject(error)
})

export default http;
