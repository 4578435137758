<template>
  <div class="home">
    <img style="width: 100%;margin: 0.3rem" src="https://casewaysp.oss-ap-southeast-2.aliyuncs.com/clogo/caseway.jpg?x-oss-process=image/resize,m_fixed,h_100" alt="">
    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <vue-pdf-app style="height: 85vh;" theme="light" :pdf="pdfUrl" :config="config"/>
    <div style="position: absolute;bottom: 2vh;right: 2px">
      <div>
        <el-button type="warning" style="min-width: 8rem;margin-bottom: 1rem" @click="showMailDialog()">Email Receipt</el-button>
      </div>
      <div>
        <el-button type="danger" style="min-width: 8rem;" @click="goFeedback()">Feedback</el-button>
      </div>
    </div>
    <el-dialog title="" v-model="dialogVisible" width="90%" top="40vh">
      <el-form :model="form">
        <el-form-item>
          <el-input type="mail" v-model="form.mail" placeholder="Enter Your E-mail"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" @click="dialogVisible = false">CANCEL</el-button>
        <el-button type="primary" @click="sendMail">SEND</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // @ is an alias to /src
  import HelloWorld from '@/components/HelloWorld.vue'
  import VuePdfApp from "vue3-pdf-app";
  // import this to use default icons for buttons
  import "vue3-pdf-app/dist/icons/main.css";
  import http from "../request/axios"

  export default {
    name: 'HomeView',
    data() {
      return {
        secret: '',
        pdfUrl: '',
        dialogVisible: false,
        dialogLoading: false,
        form: {
          mail: ''
        },
        config: {
          toolbar: false
        }
      }
    },
    created () {
      let href = window.location.href
      if(href.endsWith("#/")) {
        href = href.substr(0, href.length-2)
      }
      this.secret = href.substr(href.lastIndexOf("?") + 1, href.length)
      this.getData()
    },
    components: {
      HelloWorld,
      VuePdfApp
    },
    methods: {
      getData() {
        http.get('/base/receipt/pdf_url?secret='+this.secret).then((res) => {
          console.log(res.data)
          console.log(this.secret)
          this.pdfUrl = 'oss/' + res.data.data
        }).catch((e) => {});
      },
      showMailDialog() {
        this.dialogVisible = true
      },
      sendMail() {
        http.post('/base/receipt/mail', {secret: this.secret, to: this.form.mail}).then((res)=> {
          console.log(res)
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg)
          }
          this.$message({
            message: 'Success.',
            type: 'success',
            duration: 500,
            onClose: () => {
              this.dialogVisible = false
            }
          })
        })
      },
      goFeedback() {
        this.$router.push({ name:'about', query:{ secret: this.secret} })
      }
    }
  }
</script>
